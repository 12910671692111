import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../images/wave-white.svg";
import contact from "../images/infant-dad-phone.jpg";
import why_we from "../images/kids-with-chairs.jpg";
import why_you from "../images/kids02.jpg";
import what_you from "../images/kid-with-doctor.jpg";
import how_you from "../images/kids-with-toys.jpg";
import blocks from "../images/kids-with-blocks.jpg"
import families from "../images/families.png"
import legos from "../images/kid-with-legos.jpg"
import table from "../images/kids-at-table.jpg"
import draw from "../images/kids-drawing.jpg"
import avatar from "../images/user-white.svg";
import info from "../images/Study_Overview_Centers.png";
import phone from "../images/phone.svg";
import email from "../images/email.svg";
import Modal from "../partials/modal";
import AOS from "aos";
import { routes } from "../routes";

import {
	useSearchParams
} from "react-router-dom";
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import CtaModule from "../partials/cta-module";

export default function ContactUs() {
	const [search] = useSearchParams();
	const { t } = useTranslation();
	
	const tagManagerArgs = {
		dataLayer: {
			event: "page_view",
			page_location: document.location.href,
			page_title: t("home-meta-title"),
		},
	};
	TagManager.dataLayer(tagManagerArgs);
	useEffect(() => {
		document.title = t("home-meta-title");
		AOS.init({
			duration: 2000
		});
	}, []);
	return (
		<>
			<Nav />
			<main className="meet-the-team">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundPositionY: "center", backgroundPosition: "center calc(100% + 75px)", backgroundImage: "url(" + contact + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Contact Us</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>

				<section className="container" data-aos="zoom-y-out">
					<div className="container">
					<div className="section-text" style={{"margin-top":-40}}>
							<center><h2>Have questions or comments for our team? </h2>
							<h3>We welcome your interest and feedback!</h3></center>
					</div>
					</div>
		
					<div className="container">
					<div className="section-text">
						<center><a className="button" style={{"margin-top":15, "width":315, "font-size": "medium"}} href="mailto: cmvtransmitstudy@umassmed.edu?subject=Questions or Comments for CMV TransmIT Study!">Email us at cmvtransmitstudy@umassmed.edu<span className="jump-arrow">
									<i className="fas fa-arrow-right">
									<span className="sr-only">go</span>
									</i>
									</span>
								</a></center>
						
					</div>
					</div>
				</section>

			</main>
			
			<Footer />
		</>
	);
}
