import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import wave from "../images/wave-white.svg";
import hero_bk from "../images/kids01.jpg";
import maCcmv from "../images/ma-ccmv.png";
import natCmv from "../images/nat-cmv.png";
import cdc from "../images/cdc.png";
import cmvBug from "../images/cmv-bug.svg";
import moderna from "../images/moderna-logo.svg";
import AOS from "aos";
import { routes } from "../routes";

import CtaModule from "../partials/cta-module";
import MyDataHelps from "@careevolution/mydatahelps-js"
import {
	useSearchParams
} from "react-router-dom";
import Footer from "../partials/footer";
import Nav from "../partials/nav";
import { Layout, Card, CardTitle, SingleSurveyTask, LoadingIndicator } from "@careevolution/mydatahelps-ui"

export default function Confirmation() {
    const [loading, setLoading] = useState(true);
    const [appointmentScheduled, setAppointmentScheduled] = useState(false);
		const [linkIdentifier, setLinkIdenitifer] = useState("");
		const [tasks, setTasks] = useState([]);
		const [accessKey, setAccessKey] = useState("");
    const [sampleCollectionDate, setSampleCollectionDate] = useState("");
		const [participantInfo, setParticipantInfo] = useState(undefined);

		const [search] = useSearchParams();
		const { t } = useTranslation();
		const tagManagerArgs = {
			dataLayer: {
				event: "page_view",
				page_location: document.location.href,
				page_title: t("home-meta-title"),
			},
		};
	
		TagManager.dataLayer(tagManagerArgs);
		useEffect(() => {
			document.title = t("home-meta-title");
			AOS.init({
				duration : 2000
				});
		}, []);

    useEffect(() => {
			initialize()
			MyDataHelps.on('surveyDidFinish', initialize);

	}, []);


	function initialize() {
		var loadData = function () {

			var allTasks = [];
			var querySurveyTasksPage = function (pageID) {
				var parameters = {
					status: "incomplete"
				}
				if (pageID) {
					parameters.pageID = pageID;
				}

				return MyDataHelps.querySurveyTasks(parameters).then(function (result) {
					allTasks = allTasks.concat(result.surveyTasks);
					if (result.nextPageID) {
						querySurveyTasksPage(result.nextPageID);
					} else {
						const sortOrder = ["Stage1SampleCollectionAppointment", "Participant_Survey", "SampleCollection_Dashboard", "DescriptionofChild", "AtHomeDropOff", "ExitSurvey"];
						allTasks.sort((a,b) => {return (sortOrder.indexOf(a.surveyName) - sortOrder.indexOf(b.surveyName))});
						setTasks(allTasks);
						if (allTasks.length) {
							// replace MyDataHelps.getParticipantInfo to avoid the need to grant the Participant:read scope
							const participantID = allTasks[0].participantID;
							MyDataHelps.getParticipantInfo = function() {
								return new Promise(function(resolve) {
									resolve({ participantID: participantID });
								});
							};
						}
						setLoading(false);
					}
				});
			}
			querySurveyTasksPage(null);

			setLoading(false);
		}
		const key = search.get("participant")
		setAccessKey(search.get("participant"));
		console.log("accessKey", key);
			getAccessToken(key).then(function (result) {
				try{ 
				console.log("result.accessToken", result.accessToken);
				MyDataHelps.setParticipantAccessToken(result.accessToken, "https://mydatahelps.org/")
				setParticipantInfo(result.participantInfo);
				setSampleCollectionDate(result.participantInfo.readableAppointmentTime);
				setLinkIdenitifer(result.participantInfo.linkIdentifier);
				setAppointmentScheduled(isAppointmentScheduled(result.participantInfo.readableAppointmentTime));
				// console.log(participantInfo)
				loadData();
				if (isAppointmentScheduled(result.participantInfo.readableAppointmentTime) == false) {
					// console.log("scheduling appointment");
					scheduleAppointment(result.participantInfo.linkIdentifier);
				} else {
					// console.log('what are we doin')
				}
				}catch(e) 
				{ 
					console.error(e);
					setParticipantInfo(false);
					setLoading(false);
				}
			});
	}

	function getAccessToken(accessKey){
		const lambdaURL = process.env.REACT_APP_LAMBDA_URL;
		return fetch(lambdaURL, {
      method: 'POST',
      body: JSON.stringify({"accessKey": accessKey},
      ),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
				 'Origin': window.location,
				 'Access-Control-Request-Method': "POST"
      },
   })
      .then(function (response) { return response.json()})
			.catch((err) => {
         console.log(err.message);
      });
	}
        
    function isAppointmentScheduled(sampleCollectionDate){
			console.log("isAppointmentScheduled", sampleCollectionDate.length != 0)
			return (sampleCollectionDate.length != 0)
    }
    
    async function getSampleCollectionDate() { 
        MyDataHelps.getParticipantInfo().then(function (result) {
        return (result["customFields"]["ReadableAppointmentTime"])
        });
    }

    function scheduleAppointment(linkIdentifier) { 
			MyDataHelps.startEmbeddedSurvey(linkIdentifier, "Stage1SampleCollectionAppointment").then(function (result) {
				if (result.reason === "Completed") {
					// var toAdd = "&appointmentScheduled=true"
					// window.location = window.location.href + toAdd;
					initialize();
					setAppointmentScheduled(true);
				}
			});
    }

    function launchDemographics() {
		MyDataHelps.startEmbeddedSurvey(linkIdentifier, "Demographics").then(function (result) {
			if (result.reason === "Completed") {
                var toAdd = "&demographicsSurvey=true"
				window.location = window.location.href + toAdd;
			}
		});
	}

	function launchSurvey(surveyName) {
		MyDataHelps.startEmbeddedSurvey(linkIdentifier, surveyName).then(function (result) {
			if (result.reason === "Completed") {
				initialize();
			}
		});
	}
    
    function launchHealthSurvey() {
		MyDataHelps.startEmbeddedSurvey(linkIdentifier, "Health Status").then(function (result) {
			if (result.reason === "Completed") {
                var toAdd = "&healthSurvey=true"
				window.location = window.location.href + toAdd;
			}
		});
	}

	function printTasks(tasks) {
		if (tasks) {
			return (
				<Layout colorScheme="light">
					<div>
						{tasks.map((task) => (
							<Card>
								<SingleSurveyTask
									key={task.id.toString()}
									task={task}
								/>
							</Card>
						))}
					</div>
				</Layout>
			);
		}
		return null
	}

	return (
		<>
			<Nav />
			<main className="confirmation-page">
				<section data-aos="zoom-y-out" className="title-hero center" style={{ backgroundPositionY: "40%", backgroundImage: "url(" + hero_bk + ")" }}>
					<div className="title-hero-overlay"></div>
					<div className="container">
						<h1>Thank you for enrolling in the CMV TransmIT Study</h1>
					</div>
					<img className="title-hero-wave" src={wave} alt="" />
				</section>
				{loading && <LoadingIndicator></LoadingIndicator>}
				{!loading && !participantInfo && 
					<Layout colorScheme="auto">
					<Card>
						<CardTitle
							title="Participant Not Found"
						/>
							<div
								style={{
									padding: '16px'
								}}
							>
								Please use the link in your email or sms. If you continue to have problems, please contact the study team <a href="mailto:cmvtransmitstudy@umassmed.edu">cmvtransmitstudy@umassmed.edu</a>.
							</div>
					</Card>
				</Layout>
				}
				{!loading && participantInfo &&
					<section className="faq container first-content-section readable-width" data-aos="zoom-y-out">
						<Layout colorScheme="auto">
							<Card>
								<CardTitle
									title="Task List"
								/>
								<div
									style={{
										padding: '16px'
									}}
								>
									{printTasks(tasks)}
								</div>
							</Card>
						</Layout>

						{appointmentScheduled && sampleCollectionDate !== "" && sampleCollectionDate === "Invalid Date" && <p className="divider" style={{ maxWidth: '52rem', margin: '0 auto 0.75rem auto' }}><span>The study team will be in touch to schedule your sample collection{participantInfo.testAtHome === "True" && <span>kit pick up</span>} time. </span></p>}
			
						{appointmentScheduled && sampleCollectionDate !== "" && sampleCollectionDate !== "Invalid Date" && 
							<p className="divider" style={{ maxWidth: '52rem', margin: '0 auto 0.75rem auto' }}>
								<span> {participantInfo.testAtHome === "True" ? "Your sample collection kit will be available for pick up on": "Sample collection is on"} {sampleCollectionDate}. </span> 
								{participantInfo.testAtHome != "True" && <span style={{ color: '#4169e1', textDecoration:'underline', cursor:'pointer'}} onClick={()=> MyDataHelps.startSurvey('Stage1SampleCollectionAppointment')}>Reschedule Sample Collection</span> }
							</p>
						}


						{appointmentScheduled && sampleCollectionDate === "" && <p className="divider" style={{ maxWidth: '52rem', margin: '0 auto 0.75rem auto' }}><span>The study team will be in touch to confirm your sample collection status.</span></p>}

					</section>
				}
				<section className="highlighted">
					<div className="container">
					<h2 className="center no-subtext">Additional resources</h2>
					<div className="resources">
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://www.cdc.gov/cmv/">
								<img src={cmvBug}></img>
								<span>CMV Fact Sheet</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://www.cdc.gov/cmv/">
								<img src={cdc}></img>
								<span>cdc.gov/cmv</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://www.nationalcmv.org/">
								<img src={natCmv}></img>
								<span>nationalcmv.org</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://nowiknowcmv.com/">
								<img src={moderna}></img>
								<span>nowiknowcmv.com</span>
							</a>
						</div>
						<div className="card-wrap">
							<a className="card" target="_blank" href="https://cmvmass.org/">
								<img src={maCcmv}></img>
								<span>cmvmass.org</span>
							</a>
						</div>
					</div>
						
					</div>
				</section>
			</main>
			<Footer />
		</>
	);
}
